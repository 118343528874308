import {Meeting} from "./Meeting";

export class MeetingDetailsApiF1 {
    raceHubId = '';
    locale = '';
    createdAt = '';
    updatedAt = '';
    fomRaceId = '';
    brandColourHexadecimal = '';
    circuitSmallImage: CircuitSmallImage = new CircuitSmallImage();
    links: string[] = [];
    seasonContext: SeasonContext = new SeasonContext();
    race: Race = new Race();
    seasonYearImage = '';
    event: Event = new Event();
    meetingContext: MeetingContext = new MeetingContext();
}

export class MeetingDetails {
    meetingApiF1: MeetingDetailsApiF1 = new MeetingDetailsApiF1();
    meetingLivetimingF1?: Meeting = new Meeting();
}

export class CircuitSmallImage {
    title = '';
    path = '';
    url = '';
    public_id = '';
    raw_transformation = '';
    width = 0;
    height = 0;
}

export class LiveBlog {
    contentType = '';
    title = '';
    scribbleEventId = '';
}

export class Timetable {
    state = '';
    session = '';
    gmtOffset = '';
    description = '';
    endTime = '';
    startTime = '';
}

export class SeasonContext {
    id = '';
    contentType = '';
    createdAt = '';
    updatedAt = '';
    locale = '';
    seasonYear = '';
    currentOrNextMeetingKey = '';
    state = '';
    eventState = '';
    liveEventId = '';
    liveTimingsSource = '';
    liveBlog: LiveBlog = new LiveBlog();
    seasonState = '';
    raceListingOverride = 0;
    driverAndTeamListingOverride = 0;
    timetables: Timetable[] = [];
    replayBaseUrl = '';
    seasonContextUIState = 0;
}

export class Race {
    meetingCountryName = '';
    meetingStartDate = '';
    meetingOfficialName = '';
    meetingEndDate = '';
}

export class Event {
    meetingCountryName = '';
    meetingOfficialName = '';
    meetingStartDate = '';
    meetingEndDate = '';
    meetingName = '';
    meetingLocation = '';
    circuitSmallImage = '';
    type = '';
    url = '';
    timetables: Timetable[] = [];
}

export class MeetingContext {
    season = '';
    meetingKey = '';
    isTestEvent = false;
    state = '';
    seasonState = '';
    timetables: Timetable[] = [];
}
