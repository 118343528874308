import React from "react";
import {text} from "stream/consumers";

interface TitleProps {
    title: string,
    level?: number
}
const Title: React.FC<TitleProps> = (props) => {



    return (
        <h1 className={`text-${props.level ? 6-props.level : 5}xl font-semibold text-white`}>{ props.title }</h1>
    );
};

Title.defaultProps = {
    title: '',
    level: 1
};

export default Title;
