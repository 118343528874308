import { AnyAction } from 'redux';
import {WeatherData} from "../../models/WeatherData";
import {GET_WEATHER_DATA_SESSION, START_WEATHER_DATA_SESSION_LOADING} from "../types/weatherDataTypes";

const initialState = {
    weatherDataSession: WeatherData,
    weatherDataSessionLoading: false
};

const weatherDataReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case GET_WEATHER_DATA_SESSION:
            return {
                ...state,
                weatherDataSession: action.payload,
                weatherDataSessionLoading: false
            };
        case START_WEATHER_DATA_SESSION_LOADING:
            return {
                ...state,
                weatherDataSessionLoading: true,
            }
        default:
            return state;
    }
};

export default weatherDataReducer;
