import React from "react";

interface MeetingCardProps {
    className?: string,
    name: string,
    city: string,
    country: string
}

export const MeetingCard: React.FC<MeetingCardProps> = (props: MeetingCardProps) => {

    return (
        <div className={`text-white cursor-pointer transition duration-300 hover:scale-105 rounded ${props.className}`}>
            <h2 className="text-1xl font-semibold text-white">{props.name}</h2>
            <p className="text-gray-300">{props.city} - {props.country}</p>
        </div>
    );
};
