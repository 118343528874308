import { AnyAction } from 'redux';
import {TimingAppData} from "../../models/TimingAppData";
import {GET_TIMING_DATA_SESSION, START_TIMING_DATA_SESSION} from "../types/timingTyoes";

const initialState = {
    timingDataSession: [],
    timingDataSessionLoading: false
};

const timingReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case GET_TIMING_DATA_SESSION:
            return {
                ...state,
                timingDataSession: action.payload,
                timingDataSessionLoading: false
            };
        case START_TIMING_DATA_SESSION:
            return {
                ...state,
                timingDataSessionLoading: true,
            }
        default:
            return state;
    }
};

export default timingReducer;
