import {useParams} from "react-router-dom";
import React, {useEffect, useMemo} from "react";
import {retrieveMeetingByKey} from "./store/actions/MeetingActions";
import {useDispatch, useSelector} from "react-redux";

import {MeetingDetails} from "./models/MeetingDetails";
import Title from "./components/Title";
import {
    Button,
    CircularProgress,
    DialogContent,
    DialogTitle,
    Divider,
    Drawer,
    ModalClose,
    Sheet, Typography
} from "@mui/joy";
import {
    SportsScore,
} from "@mui/icons-material";
import SessionStepper from "./components/SessionStepper";
import MeetingContextDetails from "./components/MeetingContextDetails";
import {retrieveWeatherDataBySession} from "./store/actions/WeatherDataActions";
import {WeatherData} from "./models/WeatherData";
import {WeatherInfoCard} from "./components/WeatherInfoCard";
import ProgressSessionData from "./components/ProgressSessionData";
import {retrieveSessionData} from "./store/actions/SessionActions";
import {SessionData} from "./models/SessionData";
import {retrieveTimingDataSession} from "./store/actions/timingActions";
import TimingDataCard from "./components/TimingDataCard";
import {TimingAppData} from "./models/TimingAppData";
export const MeetingView = () => {

    const dispatch = useDispatch();
    const { key } = useParams();
    const [open, setOpen] = React.useState(false);
    const meetingDetails: MeetingDetails = useSelector((state: any) => state.meetingReducer.meetingDetails);
    const meetingDetailsLoading: boolean = useSelector((state: any) => state.meetingReducer.meetingDetailsLoading);
    const weatherDataSession: WeatherData = useSelector((state: any) => state.weatherDataReducer.weatherDataSession);
    const weatherDataSessionLoading: boolean = useSelector((state: any) => state.weatherDataReducer.weatherDataSessionLoading);
    const sessionData: SessionData = useSelector((state: any) => state.sessionReducer.sessionData);
    const sessionDataLoading: boolean = useSelector((state: any) => state.sessionReducer.sessionDataLoading);
    const timingDataSession: TimingAppData[] = useSelector((state: any) => state.timingReducer.timingDataSession);
    const timingDataSessionLoading: boolean = useSelector((state: any) => state.timingReducer.timingDataSessionLoading);

    const handleSessionChanged = (index: number): void => {
        const session: string | undefined = meetingDetails.meetingLivetimingF1?.Sessions[index]?.Path;
        if (session) {
            retrieveWeatherDataBySession(session).then(action => {
                dispatch(action);
            });
            retrieveSessionData(session).then(action => {
                dispatch(action);
            });
            retrieveTimingDataSession(session).then(action => {
                dispatch(action);
            });
        }
    }

    useEffect(() => {
        if (key) {
            retrieveMeetingByKey(Number(key)).then(action => {
                dispatch(action);
            });
        }
    }, [dispatch]);

    useEffect(() => {
    }, []);

    const {
        meetingApiF1
    } = useMemo(() => meetingDetails || {}, [meetingDetails]);

    return (
        <main className="h-screen bg-black p-5">
            {
                meetingDetailsLoading ?
                    <div className="flex flex-col w-screen h-screen justify-center items-center">
                        <CircularProgress className="mb-2" thickness={1} />
                        <Typography>Retrieve data of race...</Typography>
                    </div> :
                    (
                        <>
                            <Drawer
                                size="md"
                                variant="plain"
                                open={open}
                                onClose={() => setOpen(false)}
                                slotProps={{
                                    content: {
                                        sx: {
                                            bgcolor: 'transparent',
                                            p: { md: 3, sm: 0 },
                                            boxShadow: 'none',
                                        },
                                    },
                                }}
                            >
                                <Sheet
                                    sx={{
                                        borderRadius: 'md',
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        height: '100%',
                                        overflow: 'auto',
                                    }}
                                >
                                    <DialogTitle>Select race</DialogTitle>
                                    <ModalClose />
                                    <Divider sx={{ mt: 'auto' }} />
                                    <DialogContent sx={{ gap: 2 }}>
                                    </DialogContent>
                                </Sheet>
                            </Drawer>
                            <div className="flex items-center mb-3">
                                <img className="w-24" src={meetingApiF1?.circuitSmallImage?.url} />
                                <div className="flex-col text-start ml-4 mr-4">
                                    <div className="flex items-center">
                                        <Title title={meetingApiF1?.race?.meetingCountryName} level={3} />
                                        <img className="ml-2 w-10" src={meetingApiF1?.seasonYearImage} />
                                    </div>
                                    <Title title={meetingApiF1?.race?.meetingOfficialName} level={4} />
                                </div>
                                <Button
                                    variant="outlined"
                                    color="neutral"
                                    startDecorator={<SportsScore />}
                                    onClick={() => setOpen(true)}
                                >
                                    Change race
                                </Button>
                            </div>
                            <MeetingContextDetails meeting={meetingDetails} />
                            <SessionStepper className="mt-5" timetables={meetingApiF1?.meetingContext?.timetables} onSessionChanged={handleSessionChanged} />
                            <div className="grid grid-rows-3 grid-cols-4 grid-flow-col gap-4 mt-4 h-auto sm:h-[500px]">
                                <WeatherInfoCard className="row-span-3 col-span-full sm:col-span-1" weatherDataSessionLoading={weatherDataSessionLoading} weatherDataSession={weatherDataSession} />
                                <TimingDataCard className="row-span-3 h-full overflow-y-scroll col-span-full sm:col-span-1" timingDataSessionLoading={timingDataSessionLoading} timingDataSession={timingDataSession} />
                                <ProgressSessionData className="sm:pb-0 pb-[50px] sm:col-span-2 col-span-full" trackStatusData={sessionData?.StatusSeries} loading={sessionDataLoading} />
                            </div>
                        </>
                    )
            }

        </main>
    );
};
