import axios from 'axios';
import {MeetingsByYear} from "../../models/MeetingsByYear";
import {GET_MEETING_BY_KEY, GET_MEETINGS_BY_YEAR, START_MEETING_DETAILS_LOADING} from "../types";
import {MeetingDetails, MeetingDetailsApiF1} from "../../models/MeetingDetails";
import {useDispatch} from "react-redux";
import store from "../store";

export const retrieveMeetingByYear = async (year: number): Promise<{ type: string, payload: MeetingsByYear}> => {
    const res = await axios.get<MeetingsByYear>(`https://livetiming.formula1.com/static/${year}/Index.json`)
    return {
        type: GET_MEETINGS_BY_YEAR,
        payload: res.data
    };
}

export const retrieveMeetingByKey = async (key: number): Promise<{ type: string, payload: MeetingDetails}> => {
    store.dispatch({ type: START_MEETING_DETAILS_LOADING });
    const resApiF1 = await axios
        .get<MeetingDetailsApiF1>(`https://api.formula1.com/v1/event-tracker/meeting/${key}`,
            {
                headers: {
                    apiKey : 'qPgPPRJyGCIPxFT3el4MF7thXHyJCzAP',
                    locale: 'fr'
                }
            });
    const resLivetimingF1 = await axios.get<MeetingsByYear>(`https://livetiming.formula1.com/static/${resApiF1.data.meetingContext.season}/Index.json`);
    return {
        type: GET_MEETING_BY_KEY,
        payload: {
            meetingApiF1: resApiF1.data,
            meetingLivetimingF1: resLivetimingF1.data.Meetings.find(m => m.Key === key)
        }
    };
}
