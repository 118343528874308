import axios from 'axios';
import store from "../store";
import {WeatherData} from "../../models/WeatherData";
import {GET_SESSION_DATA, START_GET_SESSION_DATA_LOADING} from "../types/sessionTypes";

export const retrieveSessionData = async (session: string): Promise<{ type: string, payload: WeatherData}> => {
    store.dispatch({ type: START_GET_SESSION_DATA_LOADING });
    const res = await axios.get<WeatherData>(`https://livetiming.formula1.com/static/${session}SessionData.json`)
    return {
        type: GET_SESSION_DATA,
        payload: res.data
    };
}
