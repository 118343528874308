import {Timetable} from "../models/MeetingDetails";
import React, {useState} from "react";
import {Divider, Sheet, Typography} from "@mui/joy";
import {AccessTime, Check, PlayCircleOutline} from "@mui/icons-material";
import dayjs from "dayjs";
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import {formattedDate} from "../utils/DateUtils";

interface SessionStepperProps {
    className?: string,
    timetables: Timetable[],
    onSessionChanged: (index: number) => void;
}

const RenderIcon = (timetable: Timetable) => {
    switch (timetable.state) {
        case ('upcoming'):
            return <AccessTime/>
        case ('started'):
            return <PlayCircleOutline color="info"/>
        default:
            return <Check color="success"/>
    }
}

const SessionStepper: React.FC<SessionStepperProps> = (props) => {

    const [selectedTimetable, setSelectedTimetable] = useState<Timetable | null>(null);
    const handleTimetableClick = (timetable: Timetable, index: number) => {
        setSelectedTimetable(timetable);
        props.onSessionChanged(index);
    };

    return (
        <Sheet variant="outlined" sx={{ p: 2, borderRadius: 15 }} className={`flex justify-around overflow-x-scroll ${props.className}`}>
            {
                props.timetables?.sort((a, b) => {
                    if ( a.startTime < b.startTime ){
                        return -1;
                    }
                    if ( a.startTime > b.startTime ){
                        return 1;
                    }
                    return 0;
                }).map((timetable: Timetable, index: number) => {
                    return <>
                        <Sheet variant={selectedTimetable === timetable ? 'soft' : 'plain'}
                               sx={{ p:2, borderRadius: 25 }}
                               className="flex flex-col items-center cursor-pointer transition duration-200 hover:scale-105"
                               key={timetable.session}
                               onClick={() => handleTimetableClick(timetable, index)}>
                            {
                                RenderIcon(timetable)
                            }
                            <Typography fontWeight="lg" textAlign="center">
                                { timetable.description }
                            </Typography>
                            <Typography level="body-sm" textAlign="center">
                                { formattedDate(timetable.startTime) }
                            </Typography>
                        </Sheet>
                    </>
                })
            }
        </Sheet>
    );
};

SessionStepper.defaultProps = {
    timetables: []
}

export default SessionStepper;
