import { AnyAction } from 'redux';
import {Meeting} from "../../models/Meeting";
import {MeetingsByYear} from "../../models/MeetingsByYear";
import {GET_MEETING_BY_KEY, GET_MEETINGS_BY_YEAR, START_MEETING_DETAILS_LOADING} from "../types";
import {MeetingDetails} from "../../models/MeetingDetails";

const initialState = {
    meetingsByYear: MeetingsByYear,
    meetingDetails: MeetingDetails,
    meetingDetailsLoading: false
};

const meetingReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case GET_MEETINGS_BY_YEAR:
            return {
                ...state,
                meetingsByYear: action.payload,
            };
        case GET_MEETING_BY_KEY:
            return {
                ...state,
                meetingDetails: action.payload,
                meetingDetailsLoading: false
            }
        case START_MEETING_DETAILS_LOADING:
            return {
                ...state,
                meetingDetailsLoading: true,
            }
        default:
            return state;
    }
};

export default meetingReducer;
