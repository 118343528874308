export class LapData {
    Utc: string = '';
    Lap: number = 0;
}

export class TrackStatusData {
    Utc: string = '';
    TrackStatus?: TrackStatus;
    SessionStatus?: SessionStatus;
}

export enum SessionStatus {
    STARTED = 'Started',
    FINISHED = 'Finished',
    FINALISED = 'Finalised',
    ENDS = 'Ends',
    INACTIVE = 'Inactive'
}

export enum TrackStatus {
    ALL_CLEAR = 'AllClear',
    YELLOW = 'Yellow',
    SC_DEPLOYED = 'SCDeployed',
    VSC_DEPLOYED = 'VSCDeployed',
    VSC_ENDING = 'VSCEnding',
    ABORTED = 'Aborted',
    RED = 'Red',
}

export class SessionData {
    Series: LapData[] = [];
    StatusSeries: TrackStatusData[] = [];
}
