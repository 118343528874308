import axios from 'axios';
import store from "../store";
import {TimingAppData} from "../../models/TimingAppData";
import {GET_TIMING_DATA_SESSION, START_TIMING_DATA_SESSION} from "../types/timingTyoes";
import {Driver} from "../../models/Driver";

export const retrieveTimingDataSession = async (session: string): Promise<{ type: string, payload: TimingAppData[]}> => {
    store.dispatch({ type: START_TIMING_DATA_SESSION });
    const res: any = await axios.get(`https://livetiming.formula1.com/static/${session}TimingAppData.json`);
    const resDriverList: any = await axios.get(`https://livetiming.formula1.com/static/${session}DriverList.json`);
    const driversData: Driver[] = Object.values(resDriverList.data);
    let timingAppData: TimingAppData[] = Object.values(res.data.Lines);

    timingAppData.map((item: TimingAppData) => {
       item.Driver = driversData.find((driver: Driver) => driver.RacingNumber === item.RacingNumber);
    });
    timingAppData = timingAppData.sort((a: TimingAppData, b: TimingAppData) => a.Line - b.Line);
    return {
        type: GET_TIMING_DATA_SESSION,
        payload: timingAppData
    };
}
