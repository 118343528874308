import {Session} from "./Session";
import {Country} from "./Country";
import {Circuit} from "./Circuit";

export class Meeting {
    Key?: number;
    Code = '';
    Number = 0;
    Location = '';
    OfficialName = '';
    Name = '';
    Country: Country = new Country();
    Circuit: Circuit = new Circuit();
    Sessions: Session[] = [];

}
