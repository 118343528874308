import axios from 'axios';
import store from "../store";
import {WeatherData} from "../../models/WeatherData";
import {GET_WEATHER_DATA_SESSION, START_WEATHER_DATA_SESSION_LOADING} from "../types/weatherDataTypes";

export const retrieveWeatherDataBySession = async (session: string): Promise<{ type: string, payload: WeatherData}> => {
    store.dispatch({ type: START_WEATHER_DATA_SESSION_LOADING });
    const res = await axios.get<WeatherData>(`https://livetiming.formula1.com/static/${session}WeatherData.json`)
    return {
        type: GET_WEATHER_DATA_SESSION,
        payload: res.data
    };
}
