import {MeetingContext, MeetingDetails, Timetable} from "../models/MeetingDetails";
import React from "react";
import {List, ListDivider, ListItem, ListItemDecorator, Typography} from "@mui/joy";
import {BugReport, CalendarToday, Crop32, DoubleArrow, Flag, HistoryToggleOff, Place} from "@mui/icons-material";
import {applyFormat, formattedDate} from "../utils/DateUtils";

interface MeetingContextDetailsProps {
    className?: string,
    meeting: MeetingDetails
}

const RaceType = (meeting: MeetingDetails) => {
    if (meeting.meetingApiF1?.meetingContext?.isTestEvent) {
        return (
            <ListItem>
                <ListItemDecorator>
                    <BugReport />
                </ListItemDecorator>
                Tests week-end
            </ListItem>
        )
    } else if (meeting.meetingApiF1?.meetingContext?.timetables.filter((timetable: Timetable) => timetable.session === 'ss').length) {
        return (
            <ListItem>
                <ListItemDecorator>
                    <DoubleArrow />
                </ListItemDecorator>
                Sprint race
            </ListItem>
        )
    } else {
        return (
            <ListItem>
                <ListItemDecorator>
                    <Flag />
                </ListItemDecorator>
                Classic race
            </ListItem>
        )
    }
}

const MeetingContextDetails: React.FC<MeetingContextDetailsProps> = (props) => {
    return (
        <List
            className={`${props.className}`}
            orientation="horizontal"
            sx={{
                flexGrow: 0,
                flexWrap: 'wrap',
                mx: 'auto',
                '--ListItem-paddingY': '1rem',
                borderRadius: 'sm',
            }}
        >
            <ListItem>
                <ListItemDecorator>
                    <CalendarToday />
                </ListItemDecorator>
                { `${applyFormat(props.meeting.meetingApiF1?.race?.meetingStartDate, 'DD MMM')} - ${applyFormat(props.meeting.meetingApiF1?.race?.meetingEndDate, 'DD MMM')}` }
            </ListItem>
            <ListDivider inset="gutter" />
            { RaceType(props.meeting) }
            <ListDivider inset="gutter" />
            <ListItem>
                <ListItemDecorator>
                    <HistoryToggleOff />
                </ListItemDecorator>
                Round { props.meeting.meetingLivetimingF1?.Number }
            </ListItem>
            <ListDivider inset="gutter" />
            <ListItem>
                <ListItemDecorator>
                    <Place />
                </ListItemDecorator>
                <div className="flex flex-col">
                    <Typography>
                        { `${props.meeting.meetingLivetimingF1?.Country?.Name}, ${props.meeting.meetingLivetimingF1?.Location}` }
                    </Typography>
                    <Typography level="body-sm">
                        { props.meeting.meetingLivetimingF1?.Circuit?.ShortName }
                    </Typography>
                </div>
            </ListItem>
        </List>
    );
};

MeetingContextDetails.defaultProps = {
    meeting: new MeetingDetails()
}

export default MeetingContextDetails;
