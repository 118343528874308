import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

export const formattedDate = (date: string): string => {
    dayjs.extend(LocalizedFormat)
    const dateFormatted = dayjs(date);
    return dateFormatted.format('D MMM, HH:mm');
}

export const applyFormat = (date: string, format: string): string => {
    dayjs.extend(LocalizedFormat);
    const dateFormatted = dayjs(date);
    return dateFormatted.format(format);
}
