import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Home} from "./Home";
import '@fontsource/inter';
import {CssVarsProvider} from "@mui/joy";
import {Provider} from "react-redux";
import store from './store/store';
import {MeetingView} from "./MeetingView";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />
    },
    {
        path: '/meeting/:key',
        element: <MeetingView />
    }
])

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <CssVarsProvider defaultMode="dark"/>
            <RouterProvider router={router}/>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
