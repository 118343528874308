import "./Home.css";
import {Option, Select} from "@mui/joy";
import { retrieveMeetingByYear } from "./store/actions/MeetingActions";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {MeetingsByYear} from "./models/MeetingsByYear";
import {Meeting} from "./models/Meeting";
import {MeetingCard} from "./MeetingCard";
import {Link} from "react-router-dom";

export const Home = () => {
    const dispatch = useDispatch();
    const meetingsByYear: MeetingsByYear = useSelector((state: any) => state.meetingReducer.meetingsByYear);

    const handleChange = (
        event: React.SyntheticEvent | null,
        newValue: number | null,
    ) => {
        if (newValue) {
            retrieveMeetingByYear(newValue).then(action => {
                dispatch(action);
            });
        }
    };

    useEffect(() => {
        retrieveMeetingByYear(2023).then(action => {
            dispatch(action);
        });
    }, [dispatch]);

    return (
        <main className={`flex flex-row h-screen bg-cover home`}>
            <div className="flex flex-col items-center h-screen   sm:basis-1/2 backdrop-blur-sm sm:bg-black">
                <h1 className="mt-10 text-5xl font-semibold text-center text-white">Boxbox</h1>

                <Select className="mt-5" variant="soft" defaultValue={2023} onChange={handleChange} >
                    <Option value={2023}>2023</Option>
                    <Option value={2022}>2022</Option>
                    <Option value={2021}>2021</Option>
                </Select>
                { meetingsByYear.Meetings?.length > 0 &&
                    <div className="grid grid-cols-3 lg:grid-cols-4 ">
                        {
                            meetingsByYear.Meetings.map((meeting: Meeting) => {
                                return <Link to={`/meeting/${meeting.Key}`}><MeetingCard className="p-5" key={meeting.Key} name={meeting.Name} city={meeting.Location} country={meeting.Country.Name} /></Link>
                            })
                        }
                    </div>
                }
                <p className="text-slate-500 text-xs absolute bottom-0 p-4">This website is unofficial and is not associated in any way with the Formula 1 companies. F1, FORMULA ONE, FORMULA 1, FIA FORMULA ONE WORLD CHAMPIONSHIP, GRAND PRIX and related marks are trade marks of Formula One Licensing B.V.</p>
            </div>
        </main>
    );
};
