import { combineReducers } from 'redux'
import meetingReducer from './meetingReducer'
import weatherDataReducer from "./weatherDataReducer";
import sessionReducer from "./sessionReducer";
import timingReducer from "./timingReducer";

export default combineReducers({
    meetingReducer: meetingReducer,
    weatherDataReducer: weatherDataReducer,
    sessionReducer: sessionReducer,
    timingReducer: timingReducer
})
