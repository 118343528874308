import {
    Avatar,
    CircularProgress,
    List,
    ListItem,
    ListItemContent,
    ListItemDecorator,
    Sheet,
    Typography
} from "@mui/joy";
import React from "react";
import {TimingAppData} from "../models/TimingAppData";
import {DoNotDisturb} from "@mui/icons-material";

interface TimingDataCardProps {
    className?: string,
    timingDataSessionLoading: boolean,
    timingDataSession: TimingAppData[]
}

const TimingDataCard: React.FC<TimingDataCardProps> = (props) => {
    return (
        <Sheet variant="soft" className={`${props.className}`} sx={{ borderRadius: 15 }}>
            <Typography className="p-4">Classement</Typography>
            {
                props.timingDataSessionLoading ?
                    <div className="p-5 flex justify-center items-center">
                        <CircularProgress thickness={1} />
                    </div> : !props.timingDataSession.length ?
                        <div className="p-5 flex justify-center items-center flex-col">
                            <DoNotDisturb />
                            <Typography level="body-sm" textAlign="center">No classement data</Typography>
                        </div> :
                    <>
                        <List>
                            {
                                props.timingDataSession?.map((timingData) => {
                                    return <ListItem key={timingData.RacingNumber}>
                                        <ListItemDecorator>
                                            <Typography fontWeight="xl">{ timingData.Line }</Typography>
                                        </ListItemDecorator>
                                        <ListItemDecorator>
                                            <div className={`h-8 w-1 rounded-full`} style={{background: `#${timingData.Driver?.TeamColour}`}} />
                                        </ListItemDecorator>
                                        <ListItemContent>
                                            <Typography level="title-sm">{ timingData.Driver?.FullName }</Typography>
                                            <Typography level="body-sm" noWrap>
                                                { timingData.Driver?.TeamName}
                                            </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                })
                            }
                        </List>
                    </>
            }
        </Sheet>

    );
};

TimingDataCard.defaultProps = {
    timingDataSession: []
}

export default TimingDataCard;
