import React from "react";
import {SessionStatus, TrackStatus, TrackStatusData} from "../models/SessionData";
import dayjs from "dayjs";
import {Chip, CircularProgress, Sheet, Tooltip, Typography} from "@mui/joy";
import {CarCrashOutlined, DoNotDisturb, Flag, SportsScore} from "@mui/icons-material";
import {applyFormat} from "../utils/DateUtils";

interface ProgressSessionDataProps {
    className?: string,
    trackStatusData: TrackStatusData[],
    loading: boolean
}
const ProgressSessionData: React.FC<ProgressSessionDataProps> = (props) => {

    let inRaceMode: boolean = false;
    const calculateStepWidth = (stepIndex: number) => {
        const range: number = dayjs(props.trackStatusData[props.trackStatusData.length - 1].Utc).unix() - dayjs(props.trackStatusData[0].Utc).unix();
        if (stepIndex !== props.trackStatusData.length - 1) {
            const rangeStep: number = dayjs(props.trackStatusData[stepIndex + 1].Utc).unix() - dayjs(props.trackStatusData[stepIndex].Utc).unix();
            return ((rangeStep * 100) / range);
        }
    };

    const getFlag = (stepIndex: number) => {
        const step: TrackStatusData = props.trackStatusData[stepIndex];
        if (step.SessionStatus === SessionStatus.STARTED) {
            return (<Flag className="mt-5" htmlColor="#75bc85" />);
        }
        if (step.TrackStatus === TrackStatus.YELLOW) {
            return (<Flag className="mt-5" htmlColor="#e3d83b" />);
        }
        if (step.SessionStatus === SessionStatus.FINISHED) {
            return (<SportsScore className="mt-5"/>);
        }
    }

    const getMessage = (stepIndex: number) => {
        const step: TrackStatusData = props.trackStatusData[stepIndex];
        if (step.SessionStatus) {
            switch (step.SessionStatus) {
                case SessionStatus.STARTED:
                    return inRaceMode ? 'Restart of the session' : 'Start of the session';
                case SessionStatus.INACTIVE:
                    return 'Session inactive';
                case SessionStatus.FINISHED:
                    return "End of session";
                case SessionStatus.FINALISED:
                    return "Finished";
            }
        }
        if (step.TrackStatus) {
            switch (step.TrackStatus) {
                case TrackStatus.YELLOW:
                    return "Yellow flag";
                case TrackStatus.RED:
                    return "Red flag";
                case TrackStatus.ABORTED:
                    return "Session aborted";
                case TrackStatus.SC_DEPLOYED:
                    return "Safety car deployed";
                case TrackStatus.VSC_DEPLOYED:
                    return "Virtual safety car deployed";
                case TrackStatus.VSC_ENDING:
                    return "Virtual safety car ending"
                case TrackStatus.ALL_CLEAR:
                    return inRaceMode ? 'Session pending' : 'Track clear';
            }
        }
    }

    const getColorOfStep = (stepIndex: number) => {
        const step: TrackStatusData = props.trackStatusData[stepIndex];
        if (step.SessionStatus) {
            switch (step.SessionStatus) {
                case SessionStatus.STARTED:
                    inRaceMode = true;
                    return '#75bc85';
                case SessionStatus.FINISHED:
                    inRaceMode = false;
                    return 'grey';
                default:
                    return 'grey';
            }
        } else if (step.TrackStatus) {
            switch (step.TrackStatus) {
                case TrackStatus.ALL_CLEAR:
                    return inRaceMode ? '#75bc85' : 'grey';
                case TrackStatus.RED:
                    inRaceMode = false;
                    return '#ff2d2a';
                case TrackStatus.YELLOW:
                case TrackStatus.SC_DEPLOYED:
                case TrackStatus.VSC_DEPLOYED:
                    return '#e3d83b';
                default:
                    return 'grey';
            }
        }
    }

    return (
        <Sheet variant="soft" className={props.className} sx={{ borderRadius: 15 }}>
            <Typography className="p-4">Session resume</Typography>
            {
                props.loading ?
                    <div className="p-5 flex justify-center items-center">
                        <CircularProgress thickness={1} />
                    </div>
                    :
                    !props.trackStatusData?.length ?
                        <div className="p-5 flex justify-center items-center flex-col">
                            <DoNotDisturb />
                            <Typography level="body-sm" textAlign="center">No session data</Typography>
                        </div>
                        :
                        <>
                            <div className="flex justify-between mx-2 mb-1">
                                <Typography fontSize="sm" textColor="grey">
                                    { applyFormat(props.trackStatusData[0]?.Utc, 'HH:mm ss') }
                                </Typography>
                                <Typography fontSize="sm" textColor="grey">
                                    { applyFormat(props.trackStatusData[props.trackStatusData.length-1]?.Utc, 'HH:mm ss') }
                                </Typography>
                            </div>
                            <div className="flex">
                                {props.trackStatusData?.map((step, index) => (
                                    <Tooltip  placement="top"
                                              variant="outlined"
                                              arrow
                                              title={
                                                  <div>
                                                      <Typography
                                                          fontSize="sm"
                                                          textColor="grey"
                                                          startDecorator={
                                                              <Typography
                                                                  fontSize="sm"
                                                                  fontWeight="lg"
                                                                  textColor="grey"
                                                              >
                                                                  { step.SessionStatus ? 'Session updated' : 'Track event' }
                                                              </Typography>
                                                          }
                                                      >
                                                          - { applyFormat(step.Utc, 'HH:mm ss') }
                                                      </Typography>
                                                      <Typography>
                                                          { getMessage(index) }
                                                      </Typography>
                                                  </div>
                                              }>
                                        <div
                                            id={step.TrackStatus ? step.TrackStatus : step.SessionStatus}
                                            key={index}
                                            className="hover:opacity-80 transition-opacity duration-100"
                                            style={{ width: `${calculateStepWidth(index)}%`, background: getColorOfStep(index), height: '10px'}}
                                        >
                                            { getFlag(index) }
                                        </div>
                                    </Tooltip>
                                ))}
                            </div>
                        </>
            }
        </Sheet>
    );
};

export default ProgressSessionData;
