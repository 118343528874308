import { AnyAction } from 'redux';
import {SessionData} from "../../models/SessionData";
import {GET_SESSION_DATA, START_GET_SESSION_DATA_LOADING} from "../types/sessionTypes";

const initialState = {
    sessionData: SessionData,
    sessionDataLoading: false
};

const sessionReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case GET_SESSION_DATA:
            return {
                ...state,
                sessionData: action.payload,
                sessionDataLoading: false
            };
        case START_GET_SESSION_DATA_LOADING:
            return {
                ...state,
                sessionDataLoading: true,
            }
        default:
            return state;
    }
};

export default sessionReducer;
