import {CircularProgress, List, ListItem, ListItemDecorator, Sheet, Typography} from "@mui/joy";
import {
    AirRounded,
    CompressRounded,
    DoNotDisturb,
    EditRoadRounded,
    GrainRounded,
    WaterDropOutlined,
    WindPowerRounded
} from "@mui/icons-material";
import React from "react";
import {WeatherData} from "../models/WeatherData";

interface WeatherInfoCardProps {
    className?: string,
    weatherDataSessionLoading: boolean,
    weatherDataSession: WeatherData
}

export const WeatherInfoCard: React.FC<WeatherInfoCardProps> = (props) => {
    return (
        <Sheet variant="soft" className={props.className} sx={{ borderRadius: 15 }}>
            <Typography className="p-4">Weather info</Typography>
            {
                props.weatherDataSessionLoading ?
                    <div className="p-5 flex justify-center items-center">
                        <CircularProgress thickness={1} />
                    </div>
                    :
                    !props.weatherDataSession.AirTemp ?
                        <div className="p-5 flex justify-center items-center flex-col">
                            <DoNotDisturb />
                            <Typography level="body-sm" textAlign="center">No weather data</Typography>
                        </div>
                        :
                        <>
                            <div className="flex flex-col items-center mt-3 mb-3">
                                <Typography level="h1" lineHeight={1} endDecorator={
                                    <Typography level="body-sm">
                                        o
                                    </Typography>
                                } sx={{ alignItems: 'flex-start' }}>
                                    { props.weatherDataSession?.AirTemp }
                                </Typography>
                            </div>
                            <List sx={{                '--ListItem-paddingY': '10px',
                            }}>
                                <ListItem>
                                    <ListItemDecorator>
                                        <WaterDropOutlined />
                                    </ListItemDecorator>
                                    <div className="flex flex-col">
                                        <Typography fontWeight="lg" endDecorator={
                                            <Typography level="body-sm">
                                                %
                                            </Typography>
                                        } sx={{ alignItems: 'flex-end' }}>
                                            { props.weatherDataSession?.Humidity }
                                        </Typography>
                                        <Typography level="body-sm" lineHeight={1}>
                                            Humidity
                                        </Typography>
                                    </div>
                                </ListItem>
                                <ListItem>
                                    <ListItemDecorator>
                                        <CompressRounded />
                                    </ListItemDecorator>
                                    <div className="flex flex-col">
                                        <Typography fontWeight="lg" endDecorator={
                                            <Typography level="body-sm">
                                                pa
                                            </Typography>
                                        } sx={{ alignItems: 'flex-end' }}>
                                            { props.weatherDataSession?.Pressure }
                                        </Typography>
                                        <Typography level="body-sm" lineHeight={1}>
                                            Pressure
                                        </Typography>
                                    </div>
                                </ListItem>
                                <ListItem>
                                    <ListItemDecorator>
                                        <GrainRounded />
                                    </ListItemDecorator>
                                    <div className="flex flex-col">
                                        <Typography fontWeight="lg" endDecorator={
                                            <Typography level="body-sm">
                                                mm
                                            </Typography>
                                        } sx={{ alignItems: 'flex-end' }}>
                                            { props.weatherDataSession?.Rainfall }
                                        </Typography>
                                        <Typography level="body-sm" lineHeight={1}>
                                            Rainfall
                                        </Typography>
                                    </div>
                                </ListItem>
                                <ListItem>
                                    <ListItemDecorator>
                                        <EditRoadRounded />
                                    </ListItemDecorator>
                                    <div className="flex flex-col">
                                        <Typography fontWeight="lg" endDecorator={
                                            <Typography level="body-sm">
                                                o
                                            </Typography>
                                        } sx={{ alignItems: 'flex-start' }}>
                                            { props.weatherDataSession?.TrackTemp }
                                        </Typography>
                                        <Typography level="body-sm" lineHeight={1}>
                                            Track temp
                                        </Typography>
                                    </div>
                                </ListItem>
                                <ListItem>
                                    <ListItemDecorator>
                                        <WindPowerRounded />
                                    </ListItemDecorator>
                                    <div className="flex flex-col">
                                        <Typography fontWeight="lg" endDecorator={
                                            <Typography level="body-sm">
                                                o
                                            </Typography>
                                        } sx={{ alignItems: 'flex-start' }}>
                                            { props.weatherDataSession?.WindDirection }
                                        </Typography>
                                        <Typography level="body-sm" lineHeight={1}>
                                            Wind direction
                                        </Typography>
                                    </div>
                                </ListItem>
                                <ListItem>
                                    <ListItemDecorator>
                                        <AirRounded />
                                    </ListItemDecorator>
                                    <div className="flex flex-col">
                                        <Typography fontWeight="lg" endDecorator={
                                            <Typography level="body-sm">
                                                km/h
                                            </Typography>
                                        } sx={{ alignItems: 'flex-end' }}>
                                            { props.weatherDataSession?.WindSpeed }
                                        </Typography>
                                        <Typography level="body-sm" lineHeight={1}>
                                            Wind speed
                                        </Typography>
                                    </div>
                                </ListItem>
                            </List>
                            {
                                Number(props.weatherDataSession?.Rainfall) > 0 ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,256L30,250.7C60,245,120,235,180,208C240,181,300,139,360,144C420,149,480,203,540,213.3C600,224,660,192,720,186.7C780,181,840,203,900,197.3C960,192,1020,160,1080,133.3C1140,107,1200,85,1260,106.7C1320,128,1380,192,1410,224L1440,256L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg> :
                                    <></>

                            }
                        </>
            }
        </Sheet>
    );
};
